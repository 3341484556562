import type { AxiosInstance } from "axios";
import { type ListInternalManageRequestsResponse } from "@/assets/types";
import {
  type IdentityOrganization,
  type InternalManageRequest,
  type SalesforceObject,
  type SalesforceObjectSchema,
} from "@/assets/api_data_interfaces";
import { type QueryAllResponse } from "@/assets/types";
import { HandleAxiosError } from "@/assets/util";

export class ManageRequestAPI {
  axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  // List manage requesj
  async listManageRequests(
    orgId: string
  ): Promise<ListInternalManageRequestsResponse | Error> {
    try {
      return await this.axiosInstance.get<ListInternalManageRequestsResponse>(
        `/org/${orgId}/internal/manageRequest`
      );
    } catch (error) {
      return HandleAxiosError(error, "listManageRequests");
    }
  }

  // Get manage request
  async getManageRequest(
    orgId: string,
    requestId: string
  ): Promise<InternalManageRequest | Error> {
    try {
      return await this.axiosInstance.get<InternalManageRequest>(
        `/org/${orgId}/internal/manageRequest/${requestId}`
      );
    } catch (error) {
      return HandleAxiosError(error, "getManageRequest");
    }
  }

  // Create manage request
  async createManageRequest(
    orgId: string,
    request: InternalManageRequest
  ): Promise<InternalManageRequest | Error> {
    try {
      return await this.axiosInstance.post<InternalManageRequest>(
        `/org/${orgId}/internal/manageRequest`,
        request
      );
    } catch (error) {
      return HandleAxiosError(error, "createManageRequest");
    }
  }

  // Cancel manage request
  async cancelManageRequest(
    orgId: string,
    requestId: string,
    message: string
  ): Promise<InternalManageRequest | Error> {
    try {
      return await this.axiosInstance.post<InternalManageRequest>(
        `/org/${orgId}/internal/manageRequest/${requestId}/cancel`,
        null,
        {
          params: {
            message,
          },
        }
      );
    } catch (error) {
      return HandleAxiosError(error, "cancelManageRequest");
    }
  }

  // Approve manage request
  async approveManageRequest(
    orgId: string,
    requestId: string,
    message: string
  ): Promise<InternalManageRequest | Error> {
    try {
      return await this.axiosInstance.post<InternalManageRequest>(
        `/org/${orgId}/internal/manageRequest/${requestId}/approve`,
        null,
        {
          params: {
            message,
          },
        }
      );
    } catch (error) {
      return HandleAxiosError(error, "approveManageRequest");
    }
  }

  // Reject manage request
  async rejectManageRequest(
    orgId: string,
    requestId: string,
    message: string
  ): Promise<InternalManageRequest | Error> {
    try {
      return await this.axiosInstance.post<InternalManageRequest>(
        `/org/${orgId}/internal/manageRequest/${requestId}/reject`,
        null,
        {
          params: {
            message,
          },
        }
      );
    } catch (error) {
      return HandleAxiosError(error, "rejectManageRequest");
    }
  }

  // Execute manage request
  async executeManageRequest(
    orgId: string,
    requestId: string
  ): Promise<InternalManageRequest | Error> {
    try {
      return await this.axiosInstance.post<InternalManageRequest>(
        `/org/${orgId}/internal/manageRequest/${requestId}/execute`,
        null
      );
    } catch (error) {
      return HandleAxiosError(error, "executeManageRequest");
    }
  }

  async rdsQuery(orgId: string, q: string): Promise<[] | Error> {
    try {
      return await this.axiosInstance.get<[]>(
        `/org/${orgId}/internal/rds/query`,
        {
          params: {
            q,
          },
        }
      );
    } catch (error) {
      return HandleAxiosError(error, "rdsQuery");
    }
  }

  async rdsRefreshRLSUser(orgId: string): Promise<string | Error> {
    try {
      return await this.axiosInstance.post<string>(
        `/org/${orgId}/internal/rds/refresh-rls-user`,
        null
      );
    } catch (error) {
      return HandleAxiosError(error, "rdsRefreshRLSUser");
    }
  }

  async salesforceDescribeGlobal(
    orgId: string
  ): Promise<Map<string, SalesforceObject> | Error> {
    try {
      return await this.axiosInstance.get<Map<string, SalesforceObject>>(
        `/org/${orgId}/internal/salesforce/sobject/describeGlobal`
      );
    } catch (error) {
      return HandleAxiosError(error, "salesforceDescribeGlobal");
    }
  }

  async salesforceDescribeSObjectByObjectType(
    orgId: string,
    objectType: string
  ): Promise<SalesforceObjectSchema | Error> {
    try {
      return await this.axiosInstance.get<SalesforceObjectSchema>(
        `/org/${orgId}/internal/salesforce/sobject/${objectType}/describe`
      );
    } catch (error) {
      return HandleAxiosError(error, "salesforceDescribeSObjectByObjectType");
    }
  }

  async salesforceQueryByString(
    orgId: string,
    q: string
  ): Promise<QueryAllResponse | Error> {
    try {
      return await this.axiosInstance.get<QueryAllResponse>(
        `/org/${orgId}/internal/salesforce/query`,
        {
          params: {
            q,
          },
        }
      );
    } catch (error) {
      return HandleAxiosError(error, "salesforceQueryByString");
    }
  }

  async salesforceQueryById(
    orgId: string,
    objectType: string,
    id: string
  ): Promise<object | Error> {
    try {
      return await this.axiosInstance.get<object>(
        `/org/${orgId}/internal/salesforce/query`,
        {
          params: {
            id: id,
            objectType: objectType,
          },
        }
      );
    } catch (error) {
      return HandleAxiosError(error, "salesforceQueryById");
    }
  }

  async salesforceQueryPage(
    orgId: string,
    page: number
  ): Promise<QueryAllResponse | Error> {
    try {
      return await this.axiosInstance.get<QueryAllResponse>(
        `/org/${orgId}/internal/salesforce/query`,
        {
          params: {
            page: page,
          },
        }
      );
    } catch (error) {
      return HandleAxiosError(error, "salesforceQueryPage");
    }
  }

  async listAllActiveOrganizations(
    orgId: string
  ): Promise<IdentityOrganization[] | Error> {
    try {
      return await this.axiosInstance.get<IdentityOrganization[]>(
        `/org/${orgId}/internal/organization/active`
      );
    } catch (error) {
      return HandleAxiosError(error, "listAllActiveOrganizations");
    }
  }
}
