import jwtDecode from "jwt-decode";

export function HandleAxiosError(error: any, methodName: string): Error {
  const errorMessage = error.response ? error.response.data : "";
  const errorStatus = error.response ? error.response.status : "";
  // eslint-disable-next-line no-console
  console.log(
    `Failed to call API ${methodName} with error: ${errorMessage} and error status: ${errorStatus}`
  );
  return new Error(errorMessage);
}

interface JwtTokenPayload {
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
}

// validate the jwt access token from auth0.
// return true and auth0 client id if the token is valid, otherwise return [false, ""].
export function ValidateAuth0JwtToken(token: string): [boolean, string] {
  try {
    const decodedToken = jwtDecode<JwtTokenPayload>(token);

    // validate the token expiration
    const currentTime = Date.now() / 1000;
    if (!decodedToken.exp || decodedToken.exp < currentTime) {
      return [false, ""];
    }

    // validate the token issuer
    const expectedIssuer = "https://" + import.meta.env.VITE_AUTH0_DOMAIN;
    if (!decodedToken.iss || !decodedToken.iss.startsWith(expectedIssuer)) {
      return [false, ""];
    }

    // validate the token audience
    const expectedAudience = import.meta.env.VITE_AUTH0_AUDIENCE;
    if (!decodedToken.aud || !decodedToken.aud.includes(expectedAudience)) {
      return [false, ""];
    }

    return [true, decodedToken.azp];
  } catch (error) {
    return [false, ""];
  }
}

//  Clean the query by removing comments and trailing special characters.
export const cleanQuery = (query: string): string => {
  // 1. Remove inline comments (everything after --).
  query = query.replace(/--.*$/gm, "");

  // 2. Remove multi-line comments (/* ... */).
  query = query.replace(/\/\*[\s\S]*?\*\//g, "");

  // 3. Trim trailing special characters (such as ;, spaces, and newlines).
  query = query.trim().replace(/[;\s]+$/, "");

  return query;
};

// Convert a string to title case.
export function transformToTitleCase(s: string): string {
  return s
    .toLowerCase() // Convert the entire string to lowercase
    .split("_") // Split by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join the words with a space
}
