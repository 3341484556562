import AppLayout from "@/layout/AppLayout.vue";
import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: AppLayout,
      redirect: "/request",
      children: [
        {
          path: "/request",
          name: "request",
          component: () => import("@/pages/ManageRequestList.vue"),
        },
        {
          path: "/request/:id",
          name: "request-detail",
          component: () => import("@/pages/ManageRequestDetail.vue"),
        },
        {
          path: "/rds-query",
          name: "rds-query",
          component: () => import("@/pages/RDSQuery.vue"),
        },
        {
          path: "/salesforce-query",
          name: "dashboard",
          component: () => import("@/pages/SalesforceQuery.vue"),
        },
      ],
      beforeEnter: authGuard,
    },
    {
      path: "/login",
      name: "login-callback",
      component: () => import("@/pages/Login.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      component: () => import("@/pages/Logout.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      name: "UnderDevelopment",
      component: () => import("@/components/UnderDevelopment.vue"),
    },
  ],
});

export default router;
