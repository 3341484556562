import { ManageRequestAPI } from "@/api/manageRequest";
import { UserOrganizationAPI } from "@/api/userOrganization";
import { RoleAPI } from "@/api/role";
import $axios, { type AxiosInstance, type AxiosResponse } from "axios";
import auth0 from "./auth0";
interface ApiPlugin {
  manageRequest: ManageRequestAPI;
  userOrganization: UserOrganizationAPI;
  role: RoleAPI;
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $api: ApiPlugin;
  }
}

const requestSuccessFunc = async (req) => {
  // Skip authentication for public api assets.
  if (req.url.startsWith("/public/")) {
    return req;
  }
  try {
    // Check if the user is authenticated.
    if (!auth0.isAuthenticated.value) {
      console.log("You are not authenticated, redirect to login page.");
      // clear the custom auth0 client id from local storage.
      localStorage.removeItem(
        import.meta.env.CUSTOM_AUTH0_CLIENT_ID_LOCAL_STORAGE_KEY
      );
      auth0.logout({
        logoutParams: { returnTo: window.location.origin + "/login" },
      });
    }
    const token = await auth0.getAccessTokenSilently();
    console.log("Got access token:", token);
    if (token) {
      // Set the access token to the request header.
      req.headers["Authorization"] = `Bearer ${token}`;
    }
  } catch (error) {
    console.error("Failed to get access token:", error);
  }
  return req;
};

const responseSuccessFunc = (res: AxiosResponse) => {
  return res.data;
};

const responseFailFunc = (error) => {
  console.log("Failed to call api with error in responseFailFunc:", error);
  // If the response error is 403 (not authorized by auth service), logout the user.
  if (error.response && error.response.status === 403) {
    console.log("403 error, logout");
    auth0.logout({
      logoutParams: { returnTo: window.location.origin + "/login" },
    });
  }
  // Re-throw the error so that it can be caught by .catch blocks
  throw error;
};

// create axios instance with given API base url.
function createAxiosInstance(baseURL: string): AxiosInstance {
  // Create a custom axios instance for suger api.
  const axiosInstance = $axios.create({
    headers: {
      common: {
        Accept: "text/plain, aplication/json, */*",
      },
      "Content-Type": "application/json",
    },
    baseURL: baseURL,
  });

  axiosInstance.interceptors.request.use(requestSuccessFunc);
  axiosInstance.interceptors.response.use(
    responseSuccessFunc,
    responseFailFunc
  );
  return axiosInstance;
}

const sugerAxios = createAxiosInstance(import.meta.env.VITE_BASE_API_URL);

const api = {
  manageRequest: new ManageRequestAPI(sugerAxios),
  userOrganization: new UserOrganizationAPI(sugerAxios),
  role: new RoleAPI(sugerAxios),
} as ApiPlugin;

export default api;
