<script setup lang="ts">
import { ref } from "vue";

import AppMenuItem from "./AppMenuItem.vue";

const model = ref([
  {
    label: "Explore",
    items: [
      {
        label: "Management Requests",
        icon: "pi pi-fw pi-home",
        to: "/request",
      },
      { label: "RDS Query", icon: "pi pi-fw pi-database", to: "/rds-query" },
      {
        label: "Salesforce Query",
        icon: "pi pi-fw pi-cloud",
        to: "/salesforce-query",
      },
    ],
  },
  // {
  //   label: "Management",
  //   items: [
  //     {
  //       label: "Documentation",
  //       icon: "pi pi-fw pi-book",
  //       to: "/doc",
  //     },
  //     {
  //       label: "Audit Log",
  //       icon: "pi pi-fw pi-book",
  //       to: "/doc",
  //     },
  //     {
  //       label: "Team",
  //       icon: "pi pi-fw pi-book",
  //       target: "_blank",
  //       to: "/team",
  //     },
  //   ],
  // },
]);
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item v-if="!item.separator" :item="item" :index="i" />
      <li v-if="item.separator" class="menu-separator" />
    </template>
  </ul>
</template>

<style lang="scss" scoped></style>
