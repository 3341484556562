import { type AxiosInstance } from "axios";
import { HandleAxiosError } from "@/assets/util";

import type {
  ListOrganizationsByUserRow,
  ListUsersByOrganizationRow,
} from "@/assets/api_data_interfaces";

export class UserOrganizationAPI {
  axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  async listOrganizations(): Promise<ListOrganizationsByUserRow[] | Error> {
    try {
      return await this.axiosInstance.get<ListOrganizationsByUserRow[]>("/org");
    } catch (error) {
      return HandleAxiosError(error, "listOrganizations");
    }
  }

  async listUsersByOrganization(
    orgId: string
  ): Promise<ListUsersByOrganizationRow[] | Error> {
    try {
      return (
        (await this.axiosInstance.get<ListUsersByOrganizationRow[]>(
          `/org/${orgId}/user`
        )) || []
      );
    } catch (error) {
      return HandleAxiosError(error, "listUsersByOrganization");
    }
  }
}
