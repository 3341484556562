import { createAuth0 } from "@auth0/auth0-vue";
import { ValidateAuth0JwtToken } from "@/assets/util";

// Set the default auth0 client id.
let auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID;

// Check if there is custom auth0 client id in the local storage.
// If there is, use the custom auth0 client id.
const customAuth0ClientId = localStorage.getItem(
  import.meta.env.CUSTOM_AUTH0_CLIENT_ID_LOCAL_STORAGE_KEY
);
if (customAuth0ClientId) {
  auth0ClientId = customAuth0ClientId;
}

// Login with the access token in the url hash.
const urlHash = window.location.hash;
if (urlHash && urlHash.startsWith("#access_token=")) {
  const accessToken = urlHash.split("=")[1].split("&")[0];
  // Validate the access token.
  const [isTokenValid, auth0ClientIdFromToken] =
    ValidateAuth0JwtToken(accessToken);
  if (isTokenValid) {
    auth0ClientId = auth0ClientIdFromToken;
    // Save the auth0 client id to the local storage.
    localStorage.setItem(
      import.meta.env.CUSTOM_AUTH0_CLIENT_ID_LOCAL_STORAGE_KEY,
      auth0ClientId
    );
  }
}

// Check if there is orgId in the url.
// If there is, get the auth0 client id for the orgId.
const urlParams = new URLSearchParams(window.location.search);
if (urlParams.has("orgId") && urlParams.get("orgId") !== "") {
  const apiUrl =
    import.meta.env.VITE_BASE_API_URL +
    `/public/org/${urlParams.get("orgId")}/auth0ApplicationClientId`;
  // http call to get the auth0 client id for the orgId
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const customAuth0ClientId = await response.text();
  // if there is custom auth0 client id for the orgId, use it.
  if (customAuth0ClientId) {
    auth0ClientId = customAuth0ClientId;
    // Save the auth0 client id to the local storage.
    localStorage.setItem(
      import.meta.env.CUSTOM_AUTH0_CLIENT_ID_LOCAL_STORAGE_KEY,
      auth0ClientId
    );
  }
}

// Create the auth0 client plugin.
const auth0Plugin = createAuth0(
  {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: auth0ClientId,

    authorizationParams: {
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      redirect_uri: window.location.origin + "/login",
      response_type: "token",
      response_mode: "web_message",
    },
    cacheLocation: "localstorage",
  },
  {
    // Skip the redirect callback if the page URL has error=access_denied.
    skipRedirectCallback: window.location.search.includes(
      "error=access_denied"
    ),
    errorPath: "/login",
  }
);

export default auth0Plugin;
