import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Aura from "@primevue/themes/aura";
import { createPinia } from "pinia";
import apiPlugin from "./plugin/apiPlugin";
import auth0Plugin from "./plugin/auth0";

import "@/assets/styles.scss";
import "@/assets/tailwind.css";

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      prefix: "p",
      darkModeSelector: ".my-app-dark",
      cssLayer: false,
    },
  },
});
app.use(ToastService);
app.use(ConfirmationService);
app.use(auth0Plugin);

app.config.globalProperties.$api = apiPlugin;

app.mount("#app");
