<template>
  <div class="layout-topbar">
    <div class="layout-topbar-logo-container">
      <button
        class="layout-menu-button layout-topbar-action"
        @click="onMenuToggle"
      >
        <i class="pi pi-bars" />
      </button>
      <router-link to="/" class="layout-topbar-logo">
        <Avatar size="large" style="background-color: #ffffff">
          <template #icon>
            <img src="@/assets/logo/suger_v3_black.png" />
          </template>
        </Avatar>
        <span class="text-2xl font-bold">Suger Internal Console</span>
      </router-link>
    </div>

    <div class="layout-topbar-actions">
      <div class="layout-config-menu">
        <Button
          icon="pi pi-shield"
          aria-label="Security"
          rounded
          severity="warn"
          @click="toggle"
        />
        <Popover ref="securityPopover">
          <div class="flex flex-col gap-4 w-80">
            <div>
              <h1 class="text-lg font-semibold">
                Warning: You are being recorded
              </h1>
              <p class="text font-medium">
                All actions, including queries and modifications, are being
                tracked and will be reported to the manager for review.
              </p>
            </div>
          </div>
        </Popover>

        <button
          type="button"
          class="layout-topbar-action"
          @click="toggleDarkMode"
        >
          <i
            :class="['pi', { 'pi-moon': isDarkTheme, 'pi-sun': !isDarkTheme }]"
          />
        </button>
        <div class="relative">
          <button
            v-styleclass="{
              selector: '@next',
              enterFromClass: 'hidden',
              enterActiveClass: 'animate-scalein',
              leaveToClass: 'hidden',
              leaveActiveClass: 'animate-fadeout',
              hideOnOutsideClick: true,
            }"
            type="button"
            class="layout-topbar-action layout-topbar-action-highlight"
          >
            <i class="pi pi-palette" />
          </button>
          <AppConfigurator />
        </div>
      </div>

      <button
        v-styleclass="{
          selector: '@next',
          enterFromClass: 'hidden',
          enterActiveClass: 'animate-scalein',
          leaveToClass: 'hidden',
          leaveActiveClass: 'animate-fadeout',
          hideOnOutsideClick: true,
        }"
        class="layout-topbar-menu-button layout-topbar-action"
      >
        <i class="pi pi-ellipsis-v" />
      </button>

      <div class="layout-topbar-menu hidden lg:block">
        <div class="layout-topbar-menu-content">
          <button type="button" class="layout-topbar-action">
            <i class="pi pi-inbox" />
            <span>Messages</span>
          </button>
          <button
            type="button"
            class="layout-topbar-action"
            @click="toggleProfile"
          >
            <i class="pi pi-user" />
            <span>Profile</span>
          </button>
          <Popover ref="op">
            <div class="flex flex-col gap-4">
              <div>
                <ul class="list-none p-0 m-0 flex flex-col">
                  <li>
                    <Button
                      text
                      label="Logout"
                      icon="pi pi-sign-out"
                      icon-pos="left"
                      @click="logout"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useLayout } from "@/layout/composables/layout";
import AppConfigurator from "./AppConfigurator.vue";
import { useRouter } from "vue-router";

import { ref } from "vue";

const { onMenuToggle, toggleDarkMode, isDarkTheme } = useLayout();

const securityPopover = ref();
const op = ref();
const router = useRouter();

const toggle = (event) => {
  securityPopover.value.toggle(event);
};

const toggleProfile = (event) => {
  op.value.toggle(event);
};

const logout = () => {
  router.push("/logout");
};
</script>
