import { type AxiosInstance } from "axios";
import { HandleAxiosError } from "@/assets/util";

export class RoleAPI {
  axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  // List suger role
  async listSugerRoles(orgId: string): Promise<string[] | Error> {
    try {
      return await this.axiosInstance.get<string[]>(`/org/${orgId}/sugerRole`);
    } catch (error) {
      return HandleAxiosError(error, "listSugerRoles");
    }
  }
}
