import type {
  ListOrganizationsByUserRow,
  ListUsersByOrganizationRow,
  IdentityOrganization,
} from "@/assets/api_data_interfaces";
import { defineStore } from "pinia";
import api from "@/plugin/apiPlugin";
import type { SObject } from "@/assets/types";

const sugerOrgIDs = [
  "w43Vc6UfM", // Suger dev org
  "EH3R7hwfM", // Suger prod org
];

export const useSuger = defineStore("suger", {
  state: () => ({
    currentOrganization: undefined as ListOrganizationsByUserRow | undefined,
    activeOrganization: undefined as ListOrganizationsByUserRow | undefined,
    organizations: [] as ListOrganizationsByUserRow[],
    allActiveOrganizations: [] as IdentityOrganization[],
    usersInOrganization: [] as ListUsersByOrganizationRow[],
    loadingOrganizations: false,
    objects: [] as SObject[],
    objectsLoading: false,
  }),

  getters: {
    isLoadingOrganizations: (state) => state.loadingOrganizations,
    globalObjects: (state) => state.objects,
    getActiveOrganization: (state) => state.activeOrganization,
    getOrganizationName: (state) => (orgId: string) => {
      const org = state.allActiveOrganizations.find((org) => org.id === orgId);
      return org?.name;
    },
    getUserEmail: (state) => (userId: string) => {
      const user = state.usersInOrganization.find((user) => user.id === userId);
      return user?.email;
    },
  },

  actions: {
    changeCurrentOrganization(data: ListOrganizationsByUserRow) {
      this.currentOrganization = data;
    },

    changeOrganizations(data: ListOrganizationsByUserRow[]) {
      this.organizations = data;
    },

    changeAllActiveOrganizations(data: IdentityOrganization[]) {
      this.allActiveOrganizations = data;
    },

    setActiveOrganization(data: ListOrganizationsByUserRow) {
      this.activeOrganization = data;
      // fetch global objects when active organization is changed
      void this.fetchGlobalObjects().then().finally();
    },

    changeOrganizationLoadingStatus(status: boolean) {
      this.loadingOrganizations = status;
    },

    async fetchUsersInOrganization(orgId: string, refresh = false) {
      if (!refresh && this.usersInOrganization.length > 0) {
        return;
      }
      const users = await api.userOrganization.listUsersByOrganization(orgId);
      if (users instanceof Error) {
        console.error(users);
        return;
      }
      this.usersInOrganization = users;
    },

    async fetchGlobalObjects() {
      if (!this.activeOrganization?.id) {
        return;
      }
      try {
        this.objectsLoading = true;
        const res = await api.manageRequest.salesforceDescribeGlobal(
          this.activeOrganization.id
        );
        if (res instanceof Error) {
          console.error(res);
          return;
        }
        // Convert the response to an array
        this.objects = Object.values(res);
      } finally {
        this.objectsLoading = false;
      }
    },

    async fetchObjectByType(objectType: string): Promise<SObject | Error> {
      if (!this.activeOrganization?.id) {
        return new Error("No active organization found");
      }
      try {
        this.objectsLoading = true;
        const res =
          await api.manageRequest.salesforceDescribeSObjectByObjectType(
            this.activeOrganization.id,
            objectType
          );
        if (res instanceof Error) {
          console.error(res);
          return res;
        }
        const index = this.objects.findIndex(
          (obj) => obj.name === res.objectType
        );
        if (index === -1) {
          console.error("Object not found in global objects", objectType);
          return new Error("Object not found in global objects");
        }
        this.objects[index] = {
          ...this.objects[index],
          ...res,
        };
        console.log(
          `getting object schema for ${objectType}`,
          JSON.stringify(this.objects[index])
        );
        return this.objects[index];
      } finally {
        this.objectsLoading = false;
      }
    },

    async getObjectSchema(objType: string): Promise<SObject | Error> {
      const schema = this.objects.find((obj) => obj.name === objType);
      if (schema && schema.fields) {
        return new Promise((resolve) => {
          resolve(schema);
        });
      }

      return await this.fetchObjectByType(objType);
    },

    async fetchAllActiveOrganizations(orgId: string, refresh = false) {
      if (!refresh && this.allActiveOrganizations.length > 0) {
        return;
      }

      try {
        const res = await api.manageRequest.listAllActiveOrganizations(orgId);
        if (res instanceof Error) {
          console.error(res);
          return;
        }
        this.changeAllActiveOrganizations(res);
      } catch (error) {
        console.error(error);
      }
    },

    async fetchOrganizations() {
      // fetch organizations
      this.changeOrganizationLoadingStatus(true);
      const organizatons = await api.userOrganization.listOrganizations();
      if (organizatons instanceof Error) {
        console.error(organizatons);
        return;
      }
      this.changeOrganizations(organizatons);
      this.changeOrganizationLoadingStatus(false);

      // find current organization
      const validateOrganizations = organizatons.filter((org) =>
        sugerOrgIDs.includes(org.id)
      );
      if (validateOrganizations.length === 0) {
        console.error("No Suger organization found");
        return;
      }

      console.log("change current organization to", validateOrganizations[0]);
      this.changeCurrentOrganization(validateOrganizations[0]);
    },
  },
});
